import {createStore, applyMiddleware, compose} from 'redux'
import RootReducer from './RootRouter';

// import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(RootReducer, composeEnhancers(applyMiddleware()))
  

export default store