export const formatDate = (date) => {
    var d;
    if (typeof date === "string") {
      d = new Date(date);
    } else {
      d = date;
    }
  
    if (d instanceof Date) {
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      return [year, month, day].join("-");
    } else {
      return null;
    }
  };