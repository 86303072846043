import * as React from "react";
import '../index.css';
import { connect } from 'react-redux';
import "../App.css";
import axios from 'axios';
import Input from "../input/input";
import _ from 'lodash';


function PostJob(props) {

    const [jobBoards, setJobBoards] = React.useState([])
    const Industries = ["Accounting", "Airlines/Aviation", "Alternative Dispute Resolution", "Alternative Medicine", "Animation", "Apparel & Fashion", "Architecture & Planning", "Arts and Crafts", "Automotive", "Aviation & Aerospace", "Banking", "Information Services", "Information Technology and Services", "Insurance"]

    const handleJobBoards = (value) => {

        if (jobBoards.includes(value)) {
            _.remove(jobBoards, function (e) {
                return e === value
            })
        }
        else
            jobBoards.push(value)

        setJobBoards(jobBoards)
    }

    const handleChange = (field, rules, data, event) => {

        var obj = {}
        obj[field] = event.target.value
        obj['inValid'] = false
        props.changeState(field, obj, data)

    }


    const handleSubmit = () => {

        var CompanyInfo = {
            Name: props.companyInfo.Name.Name,
            CompanyName: props.companyInfo.CompanyName.CompanyName,
            Email: props.companyInfo.Email.Email,
            Phone: props.companyInfo.Phone.Phone,
            StreetAddress: props.companyInfo.StreetAddress.StreetAddress,
            StreetAddress2: props.companyInfo.StreetAddress2.StreetAddress2,
            City: props.companyInfo.City.City,
            State: props.companyInfo.State.State,
            CountryCode: props.companyInfo.CountryCode.CountryCode,
            PostalCode: props.companyInfo.PostalCode.PostalCode,
        }

        var JobLocation = {
            Address: props.jobLocation.Address.Address,
            City: props.jobLocation.City.City,
            State: props.jobLocation.State.State,
            Country: props.jobLocation.Country.Country,
            CountryCode: props.jobLocation.CountryCode.CountryCode,
            PostalCode: props.jobLocation.PostalCode.PostalCode,

        }

        var data = {
            JobTitle: props.fields.JobTitle.JobTitle,
            JobId: props.fields.JobID.JobID,
            Industry: props.fields.Industry.Industry,
            Skills: props.fields.Skills.Skills,
            SalaryMin: props.fields.MinSalary.MinSalary,
            SalaryMax: props.fields.MaxSalary.MaxSalary,
            EmployeeType: props.fields.EmployeeType.EmployeeType,
            Requirements: props.fields.Requirements.Requirements,
            JobBody: props.fields.JobBody.JobBody,
            SalaryFrequency: props.fields.SalaryFrequency.SalaryFrequency,
            SalaryCurrency: props.fields.SalaryCurrency.SalaryCurrency,
            CompanyInfo: CompanyInfo,
            JobLocation: JobLocation,
            PhysicalAddress: {
                StreetAddress: props.companyInfo.StreetAddress.StreetAddress,
                StreetAddress2: props.companyInfo.StreetAddress2.StreetAddress2,
                City: props.companyInfo.City.City,
                State: props.companyInfo.State.State,
                CountryCode: props.companyInfo.CountryCode.CountryCode,
                PostalCode: props.companyInfo.PostalCode.PostalCode,
            },

        }


        // console.log(data)
        // console.log(jobBoards.join(","))

        axios.post(`http://localhost:3000/api/job-board`, data, {
            headers: {
                'x-consumer': 'ashwaq',
                'x-api-key': '2fee53ca-526b-11ec-a8f6-8c8caabc988f'
            },
            params: {
                jobBoards: jobBoards.join(",")
            },

        }).then((res) => {
            if (res)
                console.log(res.data)

        });
    }

    return (


        <section className="create-profile-section">
            <div className="container-fluid">

                <div className="CreateProfileForm">


                    <div className="formSec">
                        <form>
                            <h5>Job Details</h5>
                            <div className=" row">
                                <Input
                                    divClass="form-group col-md-4" label="Job Title"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Job Title",
                                        type: "name"
                                    }}
                                    value={props.fields.JobTitle.JobTitle}
                                    change={(e) => handleChange("JobTitle", {}, 'jobDetails', e)}
                                    inValid={props.fields.JobTitle.inValid}

                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Job ID"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Job ID",
                                        type: "name"
                                    }}
                                    value={props.fields.JobID.JobID}
                                    change={(e) => handleChange("JobID", {}, 'jobDetails', e)}
                                    inValid={props.fields.JobID.inValid}

                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Industry"
                                    config={{ className: "form-control form-select", }}
                                    elementType="select"
                                    options={Industries}
                                    value={props.fields.Industry.Industry}
                                    change={(e) => handleChange("Industry", {}, 'jobDetails', e)}

                                />
                            </div>

                            <div className="form-row row" >



                                <Input
                                    divClass="form-group col-md-4" label="Skills"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter skills separated by comma",
                                        type: "name"
                                    }}
                                    value={props.fields.Skills.Skills}
                                    change={(e) => handleChange("Skills", {}, 'jobDetails', e)}
                                    inValid={props.fields.Skills.inValid}

                                    elementType="input"
                                />

                                <Input
                                    divClass="form-group col-md-4" label="Salary Frequency"
                                    config={{ className: "form-control form-select", }}
                                    elementType="select"
                                    options={["Hourly", "Daily", "Monthly", "Yearly"]}
                                    value={props.fields.SalaryFrequency.SalaryFrequency}
                                    change={(e) => handleChange("SalaryFrequency", {}, 'jobDetails', e)}

                                />

                                <div className="form-group">
                                    <label htmlFor="inputGender">Employee Type</label>
                                    <div className="RadioBtn">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Part Time" onChange={(e) => handleChange("EmployeeType", {}, 'jobDetails', e)} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Part Time</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Full Time" onChange={(e) => handleChange("EmployeeType", {}, 'jobDetails', e)} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Full Time</label>
                                        </div>

                                    </div>
                                </div>



                            </div>
                            <div className="form-row row">
                                <Input
                                    divClass="form-group col-md-4" label="Min Salary"
                                    config={{
                                        className: "form-control",
                                        placeholder: "",
                                        type: "number"
                                    }}
                                    value={props.fields.MinSalary.MinSalary}
                                    change={(e) => handleChange("MinSalary", {}, 'jobDetails', e)}
                                    inValid={props.fields.MinSalary.inValid}

                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Max Salary"
                                    config={{
                                        className: "form-control",
                                        placeholder: "",
                                        type: "number"
                                    }}
                                    value={props.fields.MaxSalary.MaxSalary}
                                    change={(e) => handleChange("MaxSalary", {}, 'jobDetails', e)}
                                    inValid={props.fields.MaxSalary.inValid}

                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Salary Currency"
                                    config={{ className: "form-control form-select", }}
                                    elementType="select"
                                    options={["USD", "EUR", "INR", "PND"]}
                                    value={props.fields.SalaryCurrency.SalaryCurrency}
                                    change={(e) => handleChange("SalaryCurrency", {}, 'jobDetails', e)}

                                />


                            </div>
                            <div className="form-row row">
                                <div className="form-group col-md-6">
                                    <label>Job Body</label>
                                    <textarea className='form-control' style={{ height: '90px', width: '100%' }} cols='100'></textarea>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Requirements</label>
                                    <textarea className='form-control ' style={{ height: '90px', width: '100%' }} cols='100'></textarea>
                                </div>
                            </div>


                            <h5>Company Info</h5>
                            <div className="form-row row">
                                <Input
                                    divClass="form-group col-md-4" label="Name"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Name",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.Name.Name}
                                    change={(e) => handleChange("Name", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.Name.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Company Name"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Company Name",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.CompanyName.CompnayName}
                                    change={(e) => handleChange("CompanyName", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.CompanyName.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Email"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Email",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.Email.Email}
                                    change={(e) => handleChange("Email", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.Email.inValid}
                                    elementType="input"
                                />

                            </div>
                            <div className="form-row row">
                                <Input
                                    divClass="form-group col-md-4" label="Phone No"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Phone No",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.Phone.Phone}
                                    change={(e) => handleChange("Phone", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.Phone.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Street Address"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Street Address",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.StreetAddress.StreetAddress}
                                    change={(e) => handleChange("StreetAddress", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.StreetAddress.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Street Address 2"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Street Address 2",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.StreetAddress2.StreetAddress2}
                                    change={(e) => handleChange("StreetAddress2", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.StreetAddress2.inValid}
                                    elementType="input"
                                />

                            </div>
                            <div className="form-row row">
                                <Input
                                    divClass="form-group col-md-3" label="City"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter City",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.City.City}
                                    change={(e) => handleChange("City", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.City.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-3" label="State"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter State",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.State.State}
                                    change={(e) => handleChange("State", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.State.inValid}
                                    elementType="input"
                                />

                                <Input
                                    divClass="form-group col-md-3" label="Country Code"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Country Code",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.CountryCode.CountryCode}
                                    change={(e) => handleChange("CountryCode", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.CountryCode.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-3" label="Postal Code"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Postal Code",
                                        type: "name"
                                    }}
                                    value={props.companyInfo.PostalCode.PostalCode}
                                    change={(e) => handleChange("PostalCode", {}, 'companyInfo', e)}
                                    inValid={props.companyInfo.PostalCode.inValid}
                                    elementType="input"
                                />

                            </div>
                            <div className="form-row row">
                                <div className="form-group ">
                                    <label>Company Description</label>
                                    <textarea className='form-control' style={{ height: '90px', width: '100%' }} cols='100'></textarea>
                                </div>

                            </div>



                            <h5>Job Location</h5>
                            <div className="form-row row">
                                <Input
                                    divClass="form-group col-md-4" label="Address"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Address",
                                        type: "name"
                                    }}
                                    value={props.jobLocation.Address.Address}
                                    change={(e) => handleChange("Address", {}, 'jobLocation', e)}
                                    inValid={props.jobLocation.Address.inValid}
                                    elementType="input"
                                />

                                <Input
                                    divClass="form-group col-md-4" label="City"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter City",
                                        type: "name"
                                    }}
                                    value={props.jobLocation.City.City}
                                    change={(e) => handleChange("City", {}, 'jobLocation', e)}
                                    inValid={props.jobLocation.City.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="State"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter State",
                                        type: "name"
                                    }}
                                    value={props.jobLocation.State.State}
                                    change={(e) => handleChange("State", {}, 'jobLocation', e)}
                                    inValid={props.jobLocation.State.inValid}
                                    elementType="input"
                                />

                            </div>
                            <div className="form-row row">
                                <Input
                                    divClass="form-group col-md-4" label="Country"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Country",
                                        type: "name"
                                    }}
                                    value={props.jobLocation.Country.Country}
                                    change={(e) => handleChange("Country", {}, 'jobLocation', e)}
                                    inValid={props.jobLocation.Country.inValid}
                                    elementType="input"
                                />

                                <Input
                                    divClass="form-group col-md-4" label="Country Code"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Country Code",
                                        type: "name"
                                    }}
                                    value={props.jobLocation.CountryCode.CountryCode}
                                    change={(e) => handleChange("CountryCode", {}, 'jobLocation', e)}
                                    inValid={props.jobLocation.CountryCode.inValid}
                                    elementType="input"
                                />
                                <Input
                                    divClass="form-group col-md-4" label="Postal Code"
                                    config={{
                                        className: "form-control",
                                        placeholder: "Enter Postal Code",
                                        type: "name"
                                    }}
                                    value={props.jobLocation.PostalCode.PostalCode}
                                    change={(e) => handleChange("PostalCode", {}, 'jobLocation', e)}
                                    inValid={props.jobLocation.PostalCode.inValid}
                                    elementType="input"
                                />

                            </div>

                            <h5>Select Job Boards</h5>
                            <div className="row">
                                <div class="form-group col-md-4">
                                    <div class="form-check">

                                        <input type="checkbox" value="linkedin" onClick={(e) => handleJobBoards(e.target.value)} />
                                        <span class="checkmark"></span>
                                        <label class="checkBoxContainer">&nbsp; LinkedIn
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="form-check">

                                        <input type="checkbox" value="career-builder" onClick={(e) => handleJobBoards(e.target.value)} />
                                        <span class="checkmark"></span>
                                        <label class="checkBoxContainer">&nbsp;Career Builder
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="form-check">

                                        <input type="checkbox" value="monster" onClick={(e) => handleJobBoards(e.target.value)} />
                                        <span class="checkmark"></span>
                                        <label class="checkBoxContainer">&nbsp;Monster
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="form-check">

                                        <input type="checkbox" value="equest" onClick={(e) => handleJobBoards(e.target.value)} />
                                        <span class="checkmark"></span>
                                        <label class="checkBoxContainer">&nbsp;eQuest
                                        </label>
                                    </div>
                                </div>


                            </div>

                        </form>
                    </div>



                </div>
                <div className="btn-group NextFormButtons">

                    <button className="common-btn commonBlueBtn" onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </section>



    )

}


const mapStateToProps = state => {
    return {

        fields: state.jobDetails.fields,
        companyInfo: state.companyInfo.fields,
        jobLocation: state.jobLocation.fields,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeState: (name, val, data) => dispatch({ type: "CHANGE_FIELD", name: name, val: val, data: data }),
        changeErrorState: (field, val) => dispatch({ type: "CHANGE_ERROR_STATE", field: field, val: val, data: 'fields' }),
        checkFormIsValid: () => dispatch({ type: "IS_FORM_VALID", data: 'fields' }),
        mapDatabaseToLocal: (name, res, res2) => dispatch({ type: "MAP_DATABASE_TO_LOCAl", name: name, res: res, res2: res2 })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostJob);