import React from 'react';
import Navbar from "../../pages/Navbar";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
  } from "react-router-dom";

export default () => {

    console.log('render Main Admin')

    return (
        <>            
            <Navbar />            
            <div class="content-page">                
                <div class="content">
                    <div class="container">
                        <Outlet />
                    </div>                          
                </div>
                <footer class="footer">
                    2021 - 2022 © Beanbag
                </footer>
            </div>
        </>
    )
}

